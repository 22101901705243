.support-bubble {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: linear-gradient(90deg,#136fa0, #2077bf, #146090);
  color: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-position 0.5s ease, transform 0.5s ease;
  background-position: 0% 50%;

}

.support-bubble:hover {
  cursor: pointer;
  background-position: 100% 50%;
  transform: scale(1.1);
}

.support-chat-body {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.message {
  padding: 8px 12px;
  margin-bottom: 8px;
  max-width: 80%;
}

.message.user {
  background-color: #f2f2f2;
  color: black;
  align-self: flex-end;
  margin-left: auto;
  border-radius: 0.7rem;
  transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;

}

.message.support {
  background-color: #54afece9;
  color: black;
  align-self: flex-start;
  margin-right: auto;
  border-radius: 0.7rem;
  transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;

}

.chat-avatar {
  width: 40px;
  height: 40px;
  margin: 0 10px;
}

.message-bubble {
  max-width: 50%;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 10px;

  border-radius: 15px;
  background-color: #f1f1f1;
  position: relative;
}

.message-bubble.user {
  background-color: #bcbcbc;
  text-align: left;
}

.message-bubble.support {
  background-color: #54afece9;
  text-align: start;

}

/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #174768e9 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}