.userTab {
    border-collapse: collapse;
    width: 100%;
}

.userTab td,
.userTab th {
    border: 1px solid #ddd;
    padding: 12px;
}

.userTab tr:hover {
    background-color: #ddd;
}

.userTab th {
    padding: 10px;
    text-align: center;
    background-color: #181b26;
    color: white;
}

.actions {
    text-align: center;
}