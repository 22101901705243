.p-login {
    padding-top: 6em;
}

.centered-placeholder::placeholder {
    text-align: center;
}

.centered-input-container {
    text-align: center;
    display: flex;
    justify-content: center;
}

.loading {
    max-height: 18px;
}



.checkout-form {
    max-width: 500px;
    margin: auto;
}

.card-element-wrapper {
    padding: 10px !important;
    border: 1px solid #ced4da !important;
    border-radius: 12px !important;
    background-color: #f8f9fa !important;
    transition: box-shadow 0.3s, border-color 0.3s;
}

.card-element {
    width: 100%;
}
