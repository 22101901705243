/* Canales.css */

/* .channels-list .line {
    position: absolute;
    height: 2px;
    background-color: black;
    top: 50%;
    right: 0;
    width: calc(100%); 
} */

.vertical-line {
    width: 2px;
    height: 90%; /* Ajusta esto según sea necesario */
    margin-bottom: 10%; /* Ajusta esto según sea necesario */
    background-color: #000; /* Cambia esto al color que prefieras */
    position: relative;
}

.vertical-line::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    width: 20px; /* Longitud de la línea horizontal */
    height: 2px; /* Grosor de la línea horizontal */
    background-color: #000; /* Cambia esto al color que prefieras */
}

.vertical-line::before {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(100% + 20px); /* Posición de la punta de la flecha */
    transform: translateY(-50%);
    border: solid transparent;
    border-width: 10px;
    border-left-color: #000; /* Cambia esto al color que prefieras */
}

.card {
    display: flex;
    align-items: center;
    overflow: hidden;
    /* Para asegurarse de que el contenido no se desborde */
}

.text-container {
    flex-grow: 1;
    white-space: nowrap;
    /* Para evitar que el texto se rompa en varias líneas */
    overflow: hidden;
    /* Para ocultar el texto desbordado */
    text-overflow: ellipsis;
    /* Para agregar puntos suspensivos al texto desbordado */
}

/* Nuevo estilo para el punto de estado de conexión */
.status-dot {
    position: absolute;
    top: 10px; /* Ajusta esto según sea necesario */
    right: 10px; /* Ajusta esto según sea necesario */
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.connected {
    background-color: green;
}

.disconnected {
    background-color: gray;
}

.me-3 {
    margin-right: 1rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.position-relative {
    position: relative;
}

.flex-grow-1 {
    flex-grow: 1;
}

.status-dot.connected {
    background-color: green;
}

.status-dot.disconnected {
    background-color: rgb(172, 44, 44);
}
